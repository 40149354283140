import React, {useEffect, useState} from "react"
import _ from "lodash"
import {AppSync} from "../../libraries/appsync";

export const ImgNews = ({item, attr}) =>
  _.get(item, "image[0]") ? (
    <img
      src={`${process.env.IMAGE_URL_PREFIX}information/${_.get(item, "id")}/${_.get(item, "image[0]")}`}
      alt={_.get(item, "title")}
      {...attr}
    />
  ) : <ImgUnset/>

export const ImgBanner = ({item, attr}) =>
  _.get(item, "image[0]") ? (
    <img
      src={`${process.env.IMAGE_URL_PREFIX}information/${_.get(item, "id")}/${_.get(item, "image[0]")}`}
      alt={_.get(item, "title")}
      {...attr}
    />
  ) : <ImgUnset/>

export const ImgMainVisual = ({item, attr}) =>
  _.get(item, "image[0]") ? (
    <img
      src={`${process.env.IMAGE_URL_PREFIX}information/${_.get(item, "id")}/${_.get(item, "image[0]")}`}
      alt={_.get(item, "title")}
      {...attr}
    />
  ) : <ImgUnset/>

export const ImgProduct = ({item, attr, index = 0}) =>
  _.get(item, `ecImage[${index}]`) ? (
    <img
      src={`${process.env.IMAGE_URL_PREFIX}product/${_.get(item, "Hin")}/${_.get(item, `ecImage[${index}]`)}`}
      alt={_.get(item, "Title")}
      {...attr}
    />
  ) : <ImgUnset/>

export const ImgProductModd = ({Hin}) => {
  const [imgTag, setImgTag] = useState(<ImgUnset/>)

  useEffect(() => {
    AppSync.post("/", {
      query: `
        query getProduct {
          getProduct(Hin:"${Hin}") {
            Hin
            Title
            ImgUrl
          }
        }
      `
    }).then(res => {
      if (_.get(res.data, "data.getProduct.ImgUrl")) {
        setImgTag(<img src={_.get(res.data, "data.getProduct.ImgUrl")} alt={_.get(res.data, "data.getProduct.Title")}/>)
      }
    })
  }, [Hin])

  return imgTag
}

export const ImgUnset = () => (
  <div className="no-image"><span>no image</span></div>
)
import React, {useContext} from "react"
import Layout from "../components/Layout"
import SEO from "../components/SEO"
import CartInfo from "../components/CartInfo"
import PageTitle from "../components/PageTitle";
import {ButtonTop} from "../components/Button"
import PageNavigation from "../components/PageNavigation"
import CartContext from "../components/Context/CartContext"
import classNames from "classnames";

export default () => {
  const {cartInfo, cartLoading} = useContext(CartContext)

  return (
    <Layout>
      <SEO title="カート"/>
      <section className="inner">
        <PageTitle>Cart</PageTitle>
        <div className={classNames({loading: cartLoading}, "container", "bg-white", "inner")} style={{minHeight: "190px"}}>
          <CartInfo cartInfo={cartInfo} loading={cartLoading}/>
        </div>
        <PageNavigation>
          <ButtonTop/>
        </PageNavigation>
      </section>
    </Layout>
  )
}